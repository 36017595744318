import { trapFocus } from '../theme.js';
class StickyHeader extends HTMLElement {
  constructor() {
    super();
    this.stickyHeader = document.querySelector('.header-wrapper');

    // Get and set header height
    this.headerHeight = `${this.stickyHeader.querySelector('.header-wrapper > header').offsetHeight}px`;

    // Enables the transparent header if set to 'true'
    if (getComputedStyle(document.body).getPropertyValue('--page-header-transparent') == 'true') {
      this.stickyHeader.setAttribute('data-transparent-header', 'true');
    } else if (getComputedStyle(document.body).getPropertyValue('--header-transparent-on-hero-element') == 'true') {
      this.stickyHeader.setAttribute('data-transparent-header', 'true');
    } else {
      this.stickyHeader.setAttribute('data-transparent-header', 'false');
    }

    // Registre all details and add on click event
    this.loadMegamenuDropdown();
  }

  loadMegamenuDropdown() {
    // Reset values
    this.backgroundAnimation = null;
    this.isClosing = false;
    this.isExpanding = false;

    // Find all top navigation items in mega menu
    this.details = this.querySelectorAll('details.mega-menu');

    // Loop throguht all top navigation items
    this.details.forEach((element) => {
      // Get current element
      this.summary = element.querySelector('summary');

      // Add event listener 'click' on each megamenu item
      this.summary.addEventListener('click', (event) => {
        event.preventDefault();

        // Set variable to the parent of the current element clicked
        this.activeDetails = element;

        // Reset content visibility in megamenu, by hiding all megamenu content
        if (this.activeDetails) {
          this.textHideMegamenuContent();
        }

        // Then show the clicked megamenu item content
        this.megamenuContentOnClick();
      });

      // Add event listener 'click' on each megamenu item
      element.closest('.header-wrapper').addEventListener('mouseenter', (event) => {
        // Add class to header on enter
        event.currentTarget.classList.add('expanded');
      });

      // Add event listener 'click' on each megamenu item, remove the class when mouse leaves the megamenu and close it
      element.closest('.header-wrapper').addEventListener('mouseleave', (event) => {
        event.currentTarget.classList.remove('expanded');
        if (element.open) {
          this.shrinkMegamenuContent();
        }
      });

      // Run to add data attribute to be able to navigate to the parent navigation item on 2nd click
      this.onClickSubcategory(element);

      // Run to reveal/hide level 3 navigation items on click events
      this.showChildLevelsOnClick(element);
    });
  }

  megamenuContentOnClick() {
    // If close animation is running, or is the current element not [open] we open it, else If open animation is running or current element is [open]
    if (this.isClosing || !this.activeDetails.open) {
      this.openMegamenuContent();
    } else if (this.isExpanding || this.activeDetails.open) {
      this.shrinkMegamenuContent();
    }
  }

  shrinkMegamenuContent() {
    // Set current element we close, to 'true'.
    this.isClosing = true;

    // First we check if an animation is running, then we cancel it
    if (this.backgroundAnimation) {
      this.backgroundAnimation.cancel();
    }

    // Shrink animation
    this.backgroundAnimation = this.activeDetails.querySelector('.mega-menu__background-transition').animate(
      {
        height: [`calc(100% + ${this.headerHeight})`, '0'],
        top: [`-${this.headerHeight}`, '0']
      },
      {
        duration: 200,
        easing: 'cubic-bezier(.14,.4,.59,.59)'
      }
    );

    // Animation listeners, reset variables then hide content
    (this.backgroundAnimation.onfinish = () => this.onAnimationFinishMegamenuContent(false)), this.textHideMegamenuContent();

    // If animation is cancelled then we reset the variable
    this.backgroundAnimation.oncancel = () => (this.isClosing = false);
  }

  openMegamenuContent() {
    // Set the clicked element to [open]
    this.activeDetails.open = true;

    // Is expanding
    this.isExpanding = true;

    // Start open animation
    window.requestAnimationFrame(() => this.expandMegamenuContent());
  }

  expandMegamenuContent() {
    // First we check if an animation is running, then we cancel it
    if (this.backgroundAnimation) {
      this.backgroundAnimation.cancel();
    }

    // Expand animation
    this.backgroundAnimation = this.activeDetails.querySelector('.mega-menu__background-transition').animate(
      {
        height: ['0', `calc(100% + ${this.headerHeight})`],
        top: ['0', `-${this.headerHeight}`]
      },
      {
        duration: 200,
        easing: 'cubic-bezier(.14,.4,.59,.59)'
      }
    );

    // Animation listeners
    this.backgroundAnimation.onfinish = () => this.textRevealMegamenuContent();
  }

  // Hide all content on the active details based on tailwind classes, opacity is used to smotth out the transition
  textHideMegamenuContent() {
    this.activeDetails.querySelector('.mega-menu__list').classList.add('tw-invisible');
    this.activeDetails.querySelector('.mega-menu__list').classList.add('tw-opacity-0');
    this.activeDetails.querySelector('.mega-menu__list').classList.remove('tw-opacity-100');
  }

  // Reveal all content on the active details based on tailwind classes, opacity is used to smotth out the transition
  textRevealMegamenuContent() {
    this.activeDetails.querySelector('.mega-menu__list').classList.remove('tw-invisible');
    this.activeDetails.querySelector('.mega-menu__list').classList.remove('tw-opacity-0');
    this.activeDetails.querySelector('.mega-menu__list').classList.add('tw-opacity-100');
    trapFocus(this.activeDetails.querySelector('summary').nextElementSibling, this.activeDetails);
  }

  onAnimationFinishMegamenuContent(open) {
    this.activeDetails.open = open;
    this.backgroundAnimation = null;
    this.isClosing = false;
    this.isExpanding = false;
  }

  showChildLevelsOnClick(elm) {
    elm.querySelectorAll('a[data-child]').forEach((element) => {
      element.addEventListener('click', (event) => {
        // Prevent the default redirect to allow the code below
        event.preventDefault();


        // Resets all subcategories before triggering the actual clicked element
        document.querySelectorAll('ul[data-parent]').forEach((parent) => {
          parent.classList.add('hidden', 'has-submenu');
          parent.classList.remove('open');
          parent.setAttribute('aria-expanded', "false");
        });

        // Find and set the variable of the child level that matches the parent clicked
        let categoryShow = element.parentNode.parentNode.querySelector(`ul[data-parent="${element.dataset.child}"]`);

        // If the parent dont have any child levels, then execute the redirect directly, else show the child level
        if (categoryShow == null) {
          element.setAttribute('redirect', '');
          this.executeRedirect(element);
        } else {
          //this.addOpenState(categoryShow);
          element.setAttribute('aria-expanded', "true");
          categoryShow.classList.remove('hidden');
          categoryShow.classList.add('open');
          categoryShow.setAttribute('aria-expanded', "true");
          trapFocus(element, categoryShow);
        }
      });
    });
  }

  onClickSubcategory(elm) {
    // Find all parent elements and call events
    elm.querySelectorAll('[data-child]').forEach((element) => {
      element.addEventListener('click', this.executeRedirect.bind(this, element));
      element.addEventListener('mouseenter', this.clearRedirect.bind(this, element));
      element.addEventListener('click', this.addOpenState.bind(this, element));
      element.addEventListener('mouseenter', this.removeOpenState.bind(this, element));
    });
  }

  // remove the attribute that triggers the redirect on click when mouse leaves the navigation item with the mouse
  clearRedirect(elm) {
    elm.removeAttribute('redirect');
  }

  // remove the attribute that triggers the redirect on click
  executeRedirect(elm) {
    if (elm.hasAttribute('redirect')) {
      location.href = elm.getAttribute('href');
    } else {
      elm.setAttribute('redirect', '');
    }
  }

  // control accesability attributes
  addOpenState(elm) {
    elm.classList.add('open');
    elm.setAttribute('redirect', '');
  }
  removeOpenState(elm) {
    elm.classList.remove('open');
  }

  connectedCallback() {
    this.header = document.querySelector('.section-header');
    this.stickyHeader = document.querySelector('.header-wrapper');
    this.headerIsAlwaysSticky = this.getAttribute('data-sticky-type') === 'always' || this.getAttribute('data-sticky-type') === 'reduce-logo-size';
    this.headerBounds = {};

    this.setHeaderHeight();

    // Check if the header should be transparent over the first hero element, then set the variable. This is defined in Theme settings, inside the customizer, and added in 'novicell_settings.liquid'
    this.isheaderTransparentBeforeHero = getComputedStyle(document.body).getPropertyValue('--header-transparent-on-hero-element') == 'true';

    // If 'true' we start IntersectionObserver to check if we are scrolled past the hero element
    if (this.isheaderTransparentBeforeHero) {
      this.headerTransparentBeforeHero();
    }

    window.matchMedia('(max-width: 990px)').addEventListener('change', this.setHeaderHeight.bind(this));

    if (this.headerIsAlwaysSticky) {
      this.header.classList.add('shopify-section-header-sticky');
    }

    this.currentScrollTop = 0;
    this.preventReveal = false;
    this.predictiveSearch = this.querySelector('predictive-search');

    this.onScrollHandler = this.onScroll.bind(this);
    this.hideHeaderOnScrollUp = () => (this.preventReveal = true);

    this.addEventListener('preventHeaderReveal', this.hideHeaderOnScrollUp);
    window.addEventListener('scroll', this.onScrollHandler, false);

    this.createObserver();
  }

  headerTransparentBeforeHero() {
    let heroElement = document.querySelector('.hero-with-slideshow:first-of-type');
    if (heroElement) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.stickyHeader.setAttribute('data-transparent-header', 'true');
            } else {
              this.stickyHeader.setAttribute('data-transparent-header', 'false');
            }
          });
        },
        { threshold: 0.2 }
      );
      observer.observe(heroElement);
    }
  }

  setHeaderHeight() {
    document.documentElement.style.setProperty('--header-height', `${this.header.offsetHeight - 1}px`); // subtracting 1 since header has a position top of -1px
  }

  disconnectedCallback() {
    this.removeEventListener('preventHeaderReveal', this.hideHeaderOnScrollUp);
    window.removeEventListener('scroll', this.onScrollHandler);
  }

  createObserver() {
    let observer = new IntersectionObserver((entries, observer) => {
      this.headerBounds = entries[0].intersectionRect;
      observer.disconnect();
    });

    observer.observe(this.header);
  }

  onScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (this.predictiveSearch && this.predictiveSearch.isOpen) return;

    if (scrollTop > this.currentScrollTop && scrollTop > this.headerBounds.bottom) {
      this.header.classList.add('scrolled-past-header');
      if (this.preventHide) return;
      requestAnimationFrame(this.hide.bind(this));
    } else if (scrollTop < this.currentScrollTop && scrollTop > this.headerBounds.bottom) {
      this.header.classList.add('scrolled-past-header');
      if (!this.preventReveal && !document.body.classList.contains('filter-is-open')) {
        // MENU-692: Altered)
        requestAnimationFrame(this.reveal.bind(this));
      } else {
        window.clearTimeout(this.isScrolling);

        this.isScrolling = setTimeout(() => {
          this.preventReveal = false;
        }, 66);

        requestAnimationFrame(this.hide.bind(this));
      }
    } else if (scrollTop <= this.headerBounds.top) {
      this.header.classList.remove('scrolled-past-header');
      requestAnimationFrame(this.reset.bind(this));
    }

    this.currentScrollTop = scrollTop;
  }

  hide() {
    if (this.headerIsAlwaysSticky) return;
    this.header.classList.add('shopify-section-header-hidden', 'shopify-section-header-sticky');
    this.closeMenuDisclosure();
    this.closeSearchModal();
    this.moveFilter(false); // MENU-692: Added
  }

  reveal() {
    if (this.headerIsAlwaysSticky) return;
    this.header.classList.add('shopify-section-header-sticky', 'animate');
    this.header.classList.remove('shopify-section-header-hidden');
    this.moveFilter(true); // MENU-692: Added
  }

  reset() {
    if (this.headerIsAlwaysSticky) return;
    this.header.classList.remove('shopify-section-header-hidden', 'shopify-section-header-sticky', 'animate');
  }

  closeMenuDisclosure() {
    this.disclosures = this.disclosures || this.header.querySelectorAll('header-menu');
    this.disclosures.forEach((disclosure) => disclosure.close());
  }

  closeSearchModal() {
    this.searchModal = this.searchModal || this.header.querySelector('details-modal');
    this.searchModal.close(false);
  }

  moveFilter(makeSpaceForHeader) {
    // MENU-692: Added
    // If the sticky header is set to on-scroll-up
    // It should move down an make space for the header, when the header shows on scroll up,
    // and move up again on scroll down
    if (this.getAttribute('data-sticky-type') == 'on-scroll-up') {
      let filterWrapper = document.querySelector('.facets-wrapper');
      let headerHeight = this.offsetHeight;
      if (makeSpaceForHeader) {
        // On scroll up
        filterWrapper.style.top = `${headerHeight}px`;
      } else {
        // On scroll down
        filterWrapper.style.top = '0';
      }
    }
  }
}

customElements.define('sticky-header', StickyHeader);
